import Script from 'next/script';
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const LdjsonItemList = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
        props = { 
            ...props,
            props: formatContent?.content,
        };
  } 

  const { props: propsData } = props;
  
  if (!propsData) {
    return <></>;
  }

  const listItems = propsData.map((content, indice) => {
    const { url, title, summary } = content;
    const parseSum = summary ? summary.replace(/<[^>]*>?/g, "").replace(/'/g, '') : "";

    let data = {
      "@type": "ListItem",
      position: indice + 1,
      name: title || "", // Si no hay título, proporcionamos una cadena vacía
      description: parseSum,
      url: url || "", // Si no hay URL, proporcionamos una cadena vacía
    };
    return data;
  });

  const context = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    itemListElement: listItems,
  };

  return (
    <Script
        strategy="beforeInteractive"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(context) }}
      />
  );
};

export default LdjsonItemList;
