import Script from 'next/script';
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const LdjsonBreadcrumbList = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
    props = { 
      ...props,
      sections: (!!formatContent?.isAuthor) ? formatContent?.sectionAuthor : formatContent?.sectionData,
    };
  } 

  const { sections } = props;
  
  const listItems = sections
  ? (Array.isArray(sections)
    ? (sections.length > 0
      ? sections.flatMap((content, indice) => {
          const { name, url } = content;
          const parseUrl = url ? "https://www.clarin.com/" + url : "https://www.clarin.com/";
          
          let data = {
            "@type": "ListItem",
            position: indice + 2,
            item: {
              "@id": parseUrl,
              name: name ,
            }
          };
          return data;
        })
      : [])
    : [{
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://www.clarin.com/" + `${ sections?.url?.startsWith("/") ? sections.url.replace("/","") : sections.url }`,
          name: sections.name,
        }
      }])
  : [];
  const context = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://www.clarin.com/", 
          name: "Clarín",
        }
      },
      ...listItems
    ]
  };
  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(context) }}
    />
  );
};
export default LdjsonBreadcrumbList;
