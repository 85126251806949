import Script from 'next/script';

const LdjsonNewsMediaOrg = () => {
  let data = {
    "@context": "https://schema.org",
    "@type": "NewsMediaOrganization",
    ethicsPolicy: "https://www.clarin.com/ayuda/politica-proteccion-datos-personales.html",
    masthead: "https://www.clarin.com/opinion",
    correctionsPolicy: "https://www.clarin.com/ayuda/normas-confidencialidad-privacidad.html",
    verificationFactCheckingPolicy: "https://www.clarin.com/ayuda/terminos-condiciones.html",
    contactPoint: 
        {
          "@type": "ContactPoint",
          telephone: "+54 0810-333-0365",
          contactType: "customer service",
          email: "contactenos@clarin.com"
        }
  };
  
  return (
      <Script
        strategy="beforeInteractive"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
  );
};

export default LdjsonNewsMediaOrg;
