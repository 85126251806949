import Script from 'next/script';

const LdjsonOrg = () => {
  let data = {
    "@context": "https://schema.org",
    "@type": "Organization",
    legalName: "Grupo Clarín S.A.",
    name: "Clarín",
    url: "https://www.clarin.com/",
    logo: "https://www.clarin.com/img/logo_clarin_news.png",
    foundingDate: "1945-08-28",
    founders: 
      {
        "type": "Person",
        name: "Roberto Noble",  
      },
    address: 
      {
        "@type": "PostalAddress",
        streetAddress: "Piedras 1743",
        addressLocality: "Ciudad de Buenos Aires",
        addressRegion: "Buenos Aires",
        postalCode: "C1140ABK",
        addressCountry: "AR"
      },  
  };
  
  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
    
  );
};

export default LdjsonOrg;
