import { getCookieInRender, getPathWithRouter } from "helpers";
import Script from "next/script";

const Autorefresh = ({ timerRefresh, autoRefresh = true }) => {
  return (
    <>
      <Script
        id="clatimerworker"
        type="text/js-worker"
        strategy="afterInteractive"
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: `
                let refreshTime; //seg
                let tiempoRestante; //seg
                let initDate = {};
                let params = {
                    timeoutValue: false,
                    active: false
                };

                const init = (options,autoRefresh) => {
                    if (autoRefresh) {
                        if (params.active){
                            return false;
                        }
                        refreshTime = options.refreshTime ? options.refreshTime : refreshTime;
                        tiempoRestante = refreshTime;
                        start(refreshTime);
                    }
                };

                const start = (tiempo) => {  
                    params.active = true;
                    initDate = new Date();
                    params.timeoutValue = setTimeout(finish, tiempo * 1000);
                };

                const finish = () => {                       
                    params.active = false;
                    self.postMessage({ status: 'finish' });
                };

                const pause = () => {
                    if (!params.active){
                        return false;
                    }
                    clearTimeout(params.timeoutValue);
                    params.active = false;
                    const now = new Date();
                    const segDiff = Math.round((now.getTime() - initDate.getTime()) / 1000);
                    tiempoRestante = tiempoRestante - segDiff;
                };

                const resume = () => {   
                    if (params.active){
                        return false;
                    }
                    start(tiempoRestante);
                };    

                self.addEventListener('message', function(e) {
                    try {
                        switch (e.data.cmd) {
                            case 'start':
                                init(e.data.options || {}, e.data.autoRefresh);
                                break;
                            case 'pause':
                                pause();
                                break;
                            case 'resume':
                                resume();
                                break;
                        }
                    } catch (e) { }
                }, false); 
            `,
        }}
      />
      <Script
        suppressHydrationWarning
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                const CLATimer = (function(){ 
                    const _active = ${autoRefresh} && ${autoRefresh} == false ? 'false' : 'true';
            
                    let timer = {
                        worker: undefined,
                        refreshTime: 600,
                        callback: false
                    };
            
                    var oldBrowser = {
                        timeout: false,
                        tiempoRestante: false,
                        initDate: {}
                    };
                    
                    const paramApp = ${getPathWithRouter("app") || getCookieInRender("app")};
                    
                    const _init = (callback, timeInSeconds) => {                   
                        if(paramApp) return;
                        try {
                            timer.refreshTime = timeInSeconds || timer.refreshTime;
                            timer.callback = (typeof callback === "function") ? callback : false;
                            
                            if(typeof(Worker) === "undefined"){
                                throw new Error("navigator does not support web workers");
                            }                   
            
                            var blob = new Blob(Array.prototype.map.call(document.querySelectorAll("#clatimerworker"), function (oScript) { return oScript.textContent; }),{type: "text/javascript"});
                            timer.worker = new Worker(window.URL.createObjectURL(blob));
                                    
                            timer.worker.addEventListener('message', function(e) {
            
                                if("status" in e.data && e.data.status === 'finish'){
            
                                    timer.worker.terminate();
                                    timer.worker = undefined;
            
                                    if(timer.callback){
                                        timer.callback.call(this);
                                    }
                                }                    
                                
                            }, false); 
            
                            return _start();
            
                        }catch(error){
                            //workaround para que funcione en internet explorer < 11 (no admite web workers)
                            oldBrowserTimeoutStart(timer.refreshTime,_active);
                        }
                    };
            
                    const _start = () => {            
                        return timer.worker.postMessage({
                            cmd:'start',
                            options: {
                                refreshTime: timer.refreshTime
                            },
                            autoRefresh: _active
                        });
                    };
            
                    const oldBrowserTimeoutStart = (time,_active) => {
            
                        if (!_active){
                            return;
                        }
                        
                        if(oldBrowser.timeout){
                            return;
                        }    
            
                        oldBrowser.initDate = new Date();
                        oldBrowser.tiempoRestante = time;
                        oldBrowser.timeout = setTimeout( () => {
                            if(typeof timer.callback === "function"){
                                timer.callback.call(this);
                            }
                        }, time*1000);
                    };
            
                    const _pause = () => {
                        if(paramApp) return; 
                        try{
                            if(typeof(Worker) === "undefined"){
                                throw new Error();
                            }  
                            return timer.worker.postMessage({ cmd: 'pause' });  
            
                        }catch(e){
            
                            if(!oldBrowser.timeout){
                                return;
                            }
            
                            clearTimeout(oldBrowser.timeout);
                            oldBrowser.timeout = false;
            
                            const now = new Date();
                            const segDiff = Math.round((now.getTime() - oldBrowser.initDate.getTime())/1000);
                            oldBrowser.tiempoRestante = oldBrowser.tiempoRestante - segDiff;                              
                        }
                    };
            
                    const _resume = () => {
                        if(paramApp) return; 
                        try{
                            if(typeof(Worker) === "undefined"){
                                throw new Error();
                            }  
                            return timer.worker.postMessage({ cmd: 'resume' });
            
                        }catch(e){            
                            if(oldBrowser.timeout){
                                return;
                            }            
                            oldBrowserTimeoutStart(oldBrowser.tiempoRestante,_active);            
                        }
                    };
            
                    return { init: _init, pause: _pause, resume: _resume, active: _active }
        
                })();                
                if (typeof CLATimer !== "undefined") {
                    CLATimer.init(()=>document.location.reload(true),${timerRefresh});
                }
            `,
        }}
      />
    </>
  );
};
export default Autorefresh;
