import Script from 'next/script';

const LdjsonWebsite = () => {
  let data = {
    "@context": "https://schema.org",
    "@type": "Website",
    url: "https://www.clarin.com/"
  };
  
  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

export default LdjsonWebsite;
